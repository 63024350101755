import React from 'react';
import { graphql } from 'gatsby';
import _ from 'lodash';
import PeopleFull from '../components/people-full';
import SEO from '../components/seo';
import Layout from '../containers/layout';

export const query = graphql`
  query PeopleTemplateQuery($id: String!) {
    people: sanityPerson(id: { eq: $id }) {
      slug {
        current
      }
      id
      name
      publishedAt
      linkedinUrl
      education
      categories {
        id
        categoryTitle
      }
      imageLogo {
        asset {
          _id
        }
      }
    }
  }
`;

const PeopleTemplate = props => {
  const { data, errors } = props;
  const people = _.get(data, 'people');

  return (
    <Layout>
      <SEO title={people.name} />
      <PeopleFull {...people} />
    </Layout>
  );
};

export default PeopleTemplate;
