import React from 'react';
import EmailIcon from '../../images/icons/email-icon.svg';
import LinkedinIcon from '../../images/icons/linkedin-icon.svg';
import TwitterIcon from '../../images/icons/twitter-icon.svg';

function SocialLinks(props) {
  return (
    <>
      {props.email && (
        <a href={`mailto:${props.email}`} target="_blank" rel="noopener noreferrer" className="email">
          <EmailIcon />
        </a>
      )}
      {props.linkedinUrl && (
        <a href={props.linkedinUrl} className="linkedin" target="_blank" rel="noopener noreferrer">
          <LinkedinIcon />
        </a>
      )}
      {props.twitterUrl && (
        <a href={props.twitterUrl} className="twitter" target="_blank" rel="noopener noreferrer">
          <TwitterIcon />
        </a>
      )}
    </>
  );
}

export default SocialLinks;
