import React from 'react';
import _ from 'lodash';
import { buildImageObj, imageUrlFor } from './sanity/image-url';
import SocialLinks from './social/social-links';
import ReactMarkdown from 'react-markdown/with-html';

function PeopleFull(props) {
  const { body, image } = props;
  let currentCompanies = _.get(props, 'currentCompanies', []).filter(company => !_.get(company, 'categories', []).some(category => category.categoryTitle === 'Alumni'));
  let priorCompanies = _.get(props, 'priorCompanies', []).filter(company => _.get(company, 'categories', []).some(category => category.categoryTitle !== 'Alumni'));
  let alumniCompanies = _.get(props, 'currentCompanies', []).filter(company => _.get(company, 'categories', []).some(category => category.categoryTitle === 'Alumni'));
  let categories = _.get(props, 'categories', []);

  let grid_class = 'col-sm-8 col-lg-7 offset-sm-2';
  let gutter_class = '';
  let name_class = 'offset-sm-2 offset-md-0';

  if (currentCompanies.length || priorCompanies.length || alumniCompanies.length) {
    grid_class = 'col-sm-8 col-lg-6 offset-lg-1';
    gutter_class = 'gutter-left';
    name_class = '';
  }

  return (
    <section className="container partner h-card">
      <div className="row">
        <div className="col-12">
          <div className="partner__img-lockup">
            <figure className="partner__media">
              {props.image && props.image.asset && (
                <img
                  className="partner__img u-photo"
                  alt={props.name}
                  src={imageUrlFor(buildImageObj(image))
                  }
                />
              )}
            </figure>
            <header className="partner__info vcard">
              <div>
                {props.name && (
                  <h1 className={`fn p-name ${name_class}`}>
                    {props.name}
                  </h1>
                )}
                <div className="social-buttons">
                  <SocialLinks email={props.email} linkedinUrl={props.linkedinUrl} twitterUrl={props.twitterUrl} />
                </div>
              </div>
            </header>
          </div>
        </div>
      </div>

      <div className="row">
        <div className={`${grid_class}`}>
          <div className={`partner__bio ${gutter_class}`}>
            <ul className="list-style list-style--rsaquo list-style--comfy">
              {props.education && (<li><strong>Education</strong> <span className="fw--300">{props.education}</span></li>)}
              {props.boards && (<li><strong>Boards</strong> <span className="fw--300">{props.boards}</span></li>)}
              {props.current_boards && (<li><strong>Current Boards</strong> <span className="fw--300">{props.current_boards}</span></li>)}
              {props.former_boards && (<li><strong>Former Boards</strong> <span className="fw--300">{props.former_boards}</span></li>)}
              {props.languages && (<li><strong>Languages</strong> <span className="fw--300">{props.languages}</span></li>)}
              {props.experience && (<li><strong>Prior Experience</strong> <span className="fw--300">{props.experience}</span></li>)}
              {props.awardsHonors && (<li><strong>Awards &amp; Honors</strong> <span className="fw--300">{props.awardsHonors}</span></li>)}
            </ul>
            <hr />
            <div className="fw--300">
              {body && <ReactMarkdown source={body} escapeHtml={false} />}
            </div>
            <div className="d-block d-sm-none">
              <hr />
              <div className="partner__bio">
                {currentCompanies.length > 0 && (
                  <>
                    <p className="heading-text">Current</p>
                    <div className="list-text list-text__sm">
                      {currentCompanies.map(company => (
                        <CompanyItem {...company} key={`company-item-mobile-${company.id}`} />
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
}

export default PeopleFull;
